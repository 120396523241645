import "react-datepicker/dist/react-datepicker.css";
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import DatePicker from "react-datepicker";
import Form from 'react-bootstrap/Form';
import Layout from '../../Shared/Layout/LayoutAdmin';
import Nav from 'react-bootstrap/Nav';
import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import es from 'date-fns/locale/es';
import { Inertia } from '@inertiajs/inertia'
import { Link, usePage } from '@inertiajs/inertia-react';
import { groupBy } from 'lodash';
import { hasPermission } from '../../utils/Permissions';
import { registerLocale } from "react-datepicker";
registerLocale('es', es)

const List = ({ items, advisers }) => {
    const { flash } = usePage().props
    const query = new URL(window.location).searchParams;
    const [adviserId, setAdviserId] = useState(query.get("adviser") || '');
    const [policy, setPolicy] = useState(query.get("policy") || '');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    // Grouped Items

    const groupedItems = groupBy(items, item => item.adviser_id);
    const [viewMore, setViewMore] = useState(false);

    // Check List

    const [checkedList, setCheckedList] = useState([]);
    const handleCheck = (event, selectedItem) => {
        setCheckedList(checkedList.filter((item) => item.id !== selectedItem.id)); // remove selected item

        if(event.target.checked){
            setCheckedList([...checkedList, selectedItem]); // if selected, then add it
        }
    }

    const [checkedAdviserList, setCheckedAdviserList] = useState([]);
    const handleCheckAdviser = (event, selectedAdviserId) => {
        setCheckedAdviserList(checkedAdviserList.filter((item) => item !== selectedAdviserId)); // remove selected item
        if(event.target.checked){
            setCheckedAdviserList([...checkedAdviserList, selectedAdviserId]);// if selected, then add it
        }

        setCheckedList(checkedList.filter((item) => item.adviser_id !== selectedAdviserId)); // remove all items of selected adviser
        if (event.target.checked) {
            setCheckedList([...checkedList, ...items.filter(item => item.adviser_id == selectedAdviserId)]); // add all items of selected adviser
        }
    }

    const [checkAll, setCheckAll] = useState(false);
    const handleCheckAll = (event) => {
        setCheckAll(event.target.checked);

        if (event.target.checked) {
            setCheckedAdviserList(items.map(item => item.adviser_id));
            setCheckedList([...items]);
        }else{
            setCheckedAdviserList([]);
            setCheckedList([]);
        }
    };

    function formatCurrency(amount) {
        if(isNaN(amount)) return amount;

        return amount.toLocaleString('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    // Filters

    useEffect(() => {
        setAdviserId(query.get("position") || '');
        setPolicy(query.get("policy") || '');
        setDateFrom(query.get("date_from") ? moment(query.get("date_from")).toDate() : '');
        setDateTo(query.get("date_to") ? moment(query.get("date_to")).toDate() : '');
    }, []);

    function handleChangeAdviser(e) {
        setAdviserId(e.target.value);
    }

    function handleChangePolicy(e) {
        setPolicy(e.target.value);
    }

    // Filter the list

    function search() {
        const start = moment(dateFrom).startOf('day');
        const end = moment(dateTo).startOf('day');

        Inertia.get(route('commissions.list'),
            {
                date_from: start.isValid() ? start.format('YYYY-MM-DD') : '',
                date_to: end.isValid() ? end.format('YYYY-MM-DD') : '',
                policy: policy,
                asesor: adviserId,
            },
            {
                preserveState: true,
                preserveScroll: true,
                replace: true
            }
        )
    }

    function updatePercentage(item) {
        Inertia.post(route('commissions.update', item.id), {
            percentage: document.getElementById('percentage-'+item.id).value,
        },
        {
            preserveState: true,
            preserveScroll: true,
            replace: true,
            onError: errors => {
                Swal.fire({
                    icon: "warning",
                    title: "Oops...",
                    text: "Error de validación",
                });
                return;
            },
        })
    }

    // Generate

    function generate() {
        Swal.fire({
            title: "¿Estás seguro de que la información de la comisión es correcta y deseas generar el folio? Una vez creado el folio, ya no podrá modificarse.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function (result) {
            if (result.value) {
                window.blockUI.block();

                const list = checkedList.map( ({id, adviser_id}) => ({ id, adviser_id }) );
                const groupedList = groupBy(list, item => item.adviser_id);

                Inertia.post(route('commissions.generate'), {
                    comissions: groupedList,
                },
                {
                    preserveState: true,
                    preserveScroll: true,
                    replace: true,
                    onError: errors => {
                        Swal.fire({
                            icon: "warning",
                            title: "Oops...",
                            text: "Error de validación",
                        });
                        return;
                    },
                })
            }
        });
    }

    function massiveDestroy() {
        Swal.fire({
            title: "¿Estás seguro de que deseas eliminar las comisiones seleccionadas?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true
        }).then(function (result) {
            if (result.value) {
                window.blockUI.block();

                const list = checkedList.map( ({id, adviser_id}) => ({ id, adviser_id }) );
                const groupedList = groupBy(list, item => item.adviser_id);
                const commissions = Object.values(groupedList).flat().map(commission => commission.id);

                Inertia.post(route('commissions.destroy'), {
                    commissions: commissions,
                },
                {
                    preserveState: true,
                    preserveScroll: true,
                    replace: true,
                    onSuccess: response => {
                        window.blockUI.release();
                    },
                    onError: errors => {
                        Swal.fire({
                            icon: "warning",
                            title: "Oops...",
                            text: "Error de validación",
                        });
                        return;
                    },
                });
            }
        });
    }

    const maxRecords = 20;

    return (
        <div>
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/comissions/financing" />
            </div>

            <Col className="mb-2 px-1">
                <h2>Financiamiento</h2>
            </Col>

            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            {flash.error && (
                <AlertDismissible message={flash.error} type="danger" />
            )}

            <br />

            <Nav variant="tabs" defaultActiveKey={route('commissions.list')} className="mb-5">
                <Nav.Item>
                    <Nav.Link href={route('commissions.list')}>Listado General</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href={route('commissions.invoice')}>Folio</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href={route('commissions.response')}>Respuesta</Nav.Link>
                </Nav.Item>
            </Nav>

            <Row className="mb-4 px-1">
                {hasPermission('quotes.commissions.view_any') && (
                    <Col sm={3}>
                        <Form.Label className="mb-0">Asesores/Empleados</Form.Label>
                        <Form.Select size="sm" className="form-control" value={adviserId} onChange={handleChangeAdviser}>
                            <option key='blankChoice' value={''}>Todos</option>
                            {advisers.length > 0 && (
                                advisers.map((item, index) => {
                                    return <option key={index} value={item.id}>
                                        {item.user_name} {item.lastname_ap} {item.lastname_am}
                                    </option>;
                                }))}
                        </Form.Select>
                    </Col>
                )}
                <Col sm={2}>
                    <Form.Label className="mb-0">Número de Póliza</Form.Label>
                    <Form.Control size="sm" value={policy} placeholder='' type="text" onChange={handleChangePolicy} />
                </Col>
                <Col sm={2}>
                    <Form.Label className="mb-0">Fecha Inicial</Form.Label>
                    <DatePicker popperPlacement="bottom-start" className="form-control form-control-sm" locale="es" selected={dateFrom} onChange={(date) => setDateFrom(date)} dateFormat="dd/MM/yyyy" />
                </Col>
                <Col sm={2}>
                    <Form.Label className="mb-0">Fecha Final</Form.Label>
                    <DatePicker popperPlacement="bottom-start" className="form-control form-control-sm" locale="es" selected={dateTo} onChange={(date) => setDateTo(date)} dateFormat="dd/MM/yyyy" />
                </Col>
                <Col sm={2}>
                    <Form.Label className="mb-0 d-block">&nbsp;</Form.Label>
                    <Button onClick={search} variant="primary" className='me-3 btn-sm' type="submit" >Filtrar</Button>
                </Col>
            </Row>

            <br />

            <Row className="mb-5">
                {hasPermission('quotes.commissions.view_any') && (
                    <Col sm={4}>
                        <Form.Check
                            label="Seleccionar Todos"
                            inline
                            type="checkbox"
                            name="checkAll"
                            id="checkAll"
                            onChange={handleCheckAll}
                            checked={checkAll}
                        />
                    </Col>
                )}
                <Col sm={8} className="text-end">
                    {hasPermission('quotes.commissions.delete') && (
                        <Button onClick={massiveDestroy} variant="danger" className='me-3 btn-sm' type="submit">Eliminar</Button>
                    )}
                    {hasPermission('quotes.commissions.edit') && (
                        <Button onClick={generate} variant="primary" className='me-3 btn-sm' type="submit" >Generar Folio</Button>
                    )}
                </Col>
            </Row>

            {Object.keys(groupedItems).length === 0 && (
                <div>No hay datos.</div>
            )}
            {Object.keys(groupedItems).map((key, index) => {
                const list = groupedItems[key];

                if(viewMore || index < maxRecords){
                    return (<div key={key} className="mb-5">
                        {hasPermission('quotes.commissions.view_any') && (
                            <div className="mb-2">
                                <Form.Check
                                    label={list[0]['adviser_name']+" "+list[0]['adviser_last_name_paternal']+" "+list[0]['adviser_last_name_maternal']}
                                    inline
                                    type="checkbox"
                                    name="advisers"
                                    id={"adviser_"+list[0]['adviser_id']}
                                    onChange={(e) => handleCheckAdviser(e, list[0]['adviser_id'])}
                                    checked={checkedAdviserList.includes(list[0]['adviser_id'])}
                                />
                            </div>
                        )}
                        <div className="card card-flush">
                            <div className="card-body py-4 px-2">
                                <div className="table-responsive">
                                    <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                        <thead>
                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                {hasPermission('quotes.commissions.view_any') && (
                                                    <th className='p-0 pb-3 text-center'></th>
                                                )}
                                                <th className='p-0 pb-3 text-center'>ID</th>
                                                <th className='p-0 pb-3 text-start'>Número de Póliza</th>
                                                <th className='p-0 pb-3 text-start'>Fecha de Generación de comisión</th>
                                                <th className='p-0 pb-3 text-start'>Asegurado</th>
                                                <th className='p-0 pb-3 text-start'>Número de Pago</th>
                                                <th className='p-0 pb-3 text-start'>Prima</th>
                                                <th className='p-0 pb-3 text-start'>% Financiamiento</th>
                                                <th className='p-0 pb-3 text-end'>Subtotal</th>
                                                <th className='p-0 pb-3 text-end'>Impuestos Traslado</th>
                                                <th className='p-0 pb-3 text-end'>Impuestos Retenidos</th>
                                                <th className='p-0 pb-3 text-end'>Comisión final</th>
                                                {hasPermission('quotes.commissions.edit') && (
                                                    <th className='p-0 pb-3 text-start'></th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item) => {
                                                return (
                                                    <tr key={item["id"]}>
                                                        {hasPermission('quotes.commissions.view_any') && (
                                                            <td className="text-gray-800 fw-bold fs-6 text-center">
                                                                <Form.Check
                                                                    label=""
                                                                    inline
                                                                    type="checkbox"
                                                                    name="comissions"
                                                                    id={"comission_"+item['id']}
                                                                    onChange={(e) => handleCheck(e, item)}
                                                                    checked={checkedList.some((obj) => obj.id == item['id'])}
                                                                />
                                                            </td>
                                                        )}
                                                        <td className="text-gray-800 fw-bold fs-6 text-center">{item['id']}</td>
                                                        <td className="text-gray-800 fw-bold fs-6">{item['policy_number']}</td>
                                                        <td className="text-gray-800 fw-bold fs-6">{item['created_at']}</td>
                                                        <td className="text-gray-800 fw-bold fs-6">{item['insured_name'] + " " + item['insured_last_name_paternal'] + " " + item['insured_last_name_maternal']}</td>
                                                        <td className="text-gray-800 fw-bold fs-6">{item['number_of_payment']}</td>
                                                        <td className="text-gray-800 fw-bold fs-6">{formatCurrency(item['p_total'])}</td>
                                                        <td className="text-gray-800 fw-bold fs-6 text-end">
                                                            {hasPermission('quotes.commissions.edit') && (
                                                                <Form.Control
                                                                    id={'percentage-'+item.id}
                                                                    type="text"
                                                                    defaultValue={item.percentage}
                                                                    style={{ width: '65px' }}
                                                                />
                                                            )}
                                                            {! hasPermission('quotes.commissions.edit') && (
                                                                <span>{item.percentage}</span>
                                                            )}
                                                        </td>
                                                        <td className="text-gray-800 fw-bold fs-6 text-end">{formatCurrency(item['subtotal'])}</td>
                                                        <td className="text-gray-800 fw-bold fs-6 text-end">{formatCurrency(item['total_transfer_taxes'])}</td>
                                                        <td className="text-gray-800 fw-bold fs-6 text-end">{formatCurrency(item['total_retention_taxes'])}</td>
                                                        <td className="text-gray-800 fw-bold fs-6 text-end">{formatCurrency(item['total'])}</td>
                                                        {hasPermission('quotes.commissions.edit') && (
                                                            <td className="text-gray-800 fw-bold fs-6">
                                                                <a onClick={() => updatePercentage(item)} className='btn-sm cursor-pointer'>
                                                                    Recalcular
                                                                </a>
                                                            </td>
                                                        )}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>);
                }else{
                    return '';
                }
            })}

            {Object.keys(groupedItems).length > maxRecords && <>
                <div className="text-center">
                    {!viewMore && <Link onClick={(e) => {e.preventDefault(); setViewMore(true)}} variant="primary" className='btn-sm' >Ver Más</Link>}
                    {viewMore && <Link onClick={(e) => {e.preventDefault(); setViewMore(false)}} variant="primary" className='btn-sm' >Ver Menos</Link>}
                </div>
            </>}
        </div>
    );
}

List.layout = page => <Layout children={page} title="Financiamiento" />

export default List
