import React, { useState, forwardRef, useImperativeHandle } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { usePage } from '@inertiajs/inertia-react';
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";

const InvoiceDetails = forwardRef((props, ref) => {
    const { flash, auth } = usePage().props;
    const [errors, setErrors] = useState({});

    const [dialog, setDialog] = useState(false);
    const closeDialog = () => setDialog(false);
    const showDialog = () => setDialog(true);

    useImperativeHandle(ref, () => {
        return {
            openDialog(order_id) {
                setOrderId(order_id);
                getInvoiceDetails(order_id, 1);
            },
        }
    });

    /* GET ITEMS */

    const [orderId, setOrderId] = useState(null);
    const [items, setItems] = useState([]);

    function getInvoiceDetails(order_id, page_number) {
        showDialog();

        axios.get(route('commissions.invoice.details', order_id), {
            'page': page_number
        })
        .then((response) => {
            setItems(response.data['items']);
        }).catch((error) => {
            toastr.error("Algo salió mal, intentalo nuevamente");
        });
    }

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    };

    return <>
        {/* <!--begin:Appointments Modal--> */}
        <Modal show={dialog} onHide={closeDialog} scrollable={true} size="xl" id="dialog">
            <Modal.Header closeButton className="py-4">
                <Modal.Title>Detalles</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {items.data == undefined || items.data.length == 0 ? (
                    <div className="fw-semibold text-muted">Aún no hay registros de retiro</div>
                ) : (
                    <div className="table-responsive">
                        <table className="table table-row-bordered table-row-solid">
                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400">
                                    <th className='text-start'>Número de Póliza</th>
                                    <th className='text-start'>Fecha de Generación de comisión</th>
                                    <th className='text-start'>Asegurado</th>
                                    <th className='text-start'>Número de Pago</th>
                                    <th className='text-start'>Prima</th>
                                    <th className='text-start'>Financiamiento</th>
                                    <th className='text-start'>Subtotal</th>
                                    <th className='text-start'>Impuestos Traslado</th>
                                    <th className='text-start'>Impuestos Retenidos</th>
                                    <th className='text-start'>Comisión final</th>
                                </tr>
                            </thead>

                            <tbody>
                                {items.data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-gray-800 fw-bold text-start">{item['policy_number']}</td>
                                            <td className="text-gray-800 fw-bold text-start">{item['created_at']}</td>
                                            <td className="text-gray-800 fw-bold text-start">{item['insured_name'] + " " + item['insured_last_name_paternal'] + " " + item['insured_last_name_maternal']}</td>
                                            <td className="text-gray-800 fw-bold text-start">{item['number_of_payment']}</td>
                                            <td className="text-gray-800 fw-bold text-start">{formatCurrency(item['p_total'])}</td>
                                            <td className="text-gray-800 fw-bold text-start">{item['percentage']}%</td>
                                            <td className="text-gray-800 fw-bold text-start">{formatCurrency(item['subtotal'])}</td>
                                            <td className="text-gray-800 fw-bold text-start">{formatCurrency(item['total_transfer_taxes'])}</td>
                                            <td className="text-gray-800 fw-bold text-start">{formatCurrency(item['total_retention_taxes'])}</td>
                                            <td className="text-gray-800 fw-bold text-start">{formatCurrency(item['total'])}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {items.last_page > 1 && (
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Siguiente »"
                                onPageChange={(page) => getInvoiceDetails(orderId, page.selected + 1)}
                                pageRangeDisplayed={5}
                                pageCount={items.last_page}
                                previousLabel="« Anterior"
                                renderOnZeroPageCount={null}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        )}
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={closeDialog} className="btn-sm" variant="secondary">Cerrar</Button>
            </Modal.Footer>
        </Modal>
        {/* <!--end:Appointments Modal--> */}
    </>;
})

export default InvoiceDetails
