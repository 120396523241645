import AlertDismissible from '../../../Shared/Components/AlertDismissible';
import Breadcrumbs from '../../../Shared/Components/Breadcrumbs';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Layout from '../../../Shared/Layout/LayoutAdmin';
import React from 'react'
import Row from 'react-bootstrap/Row';
import classNames from "classnames";
import { useForm, usePage } from '@inertiajs/inertia-react';

const UploadExcel = () => {
    const { flash } = usePage().props
    const accept_types = '.xls,.xlsx,.csv';

    const { data, setData, post, processing, errors, progress, reset } = useForm({
        file: null,
    })

    function submit (e) {
        e.preventDefault()
        post(route('policy-application.import.store'), {
            preserveScroll: true
        })
    }

    return (
        <>
            <Row>
                <div className="mb-7 mt-7">
                    <Breadcrumbs currentPath="/admin/quotes/insurance/policy/import" />
                </div>
                <Col>
                    <h2>Carga/Creación de Polizas</h2>
                </Col>
                <Col className='text-end'>
                    <Button href="/templates/create_policy_template.xlsx" variant="primary" className='me-3' disabled={processing} type="submit">Descargar Template Excel</Button>
                </Col>
                {flash.success && (
                    <AlertDismissible message={flash.success} type="success" />
                )}
                {flash.error && (
                    <AlertDismissible message={flash.error} type="danger" error="error"/>
                )}
                <Form onSubmit={submit}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Archivo de Excel para Importar</Form.Label>
                                <Form.Control type="file" className={classNames({ 'is-invalid': (errors.file) ? true : false, })} onChange={e => setData('file', e.target.files[0])} aria-invalid={errors.file ? "true" : "false"} accept={accept_types} />
                                {errors.file && <div className="mt-2 text-danger">{errors.file}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3">
                            {progress && (
                                <progress value={progress.percentage} max="100">
                                    {progress.percentage}%
                                </progress>
                            )}
                            <Button variant="primary" className='me-3' disabled={processing} type="submit">Importar Archivo</Button>
                            <Button onClick={() => window.history.back()} variant="secondary" type="normal">Regresar</Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </>
    );
}

UploadExcel.layout = page => <Layout children={page} title="Importar desde Excel" />

export default UploadExcel
